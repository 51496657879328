import {
  BEGIN_API_CALL,
  END_API_CALL,
  GET_VIDEO_NAME,
  CHECK_USER_AUTHENTICATION,
  UPDATE_OVERLAY_STATE,
  SET_USER_TOKENS,
  UPDATE_USER_DETAILS,
  GET_DRAFT_REPORT,
  UPDATE_REPORT_LISTING_DATA,
  UPDATE_REPORT_FAV_LISTING,
  UPDATE_PREFERRED_VIEW,
  UPDATE_REPORT_FAV_LISTING_BY_CATEGORY,
  UPDATE_SEARCHED_REPORTS_DATA,
  UPDATE_REPORT_LISTING_DATA_BY_CATEGORY,
  UPDATE_NO_FAV_DATA,
  UPDATE_NO_REPORT_DATA,
  UPDATE_NO_CONTACT_DATA,
  UPDATE_MASTER_SEARCHED_DATA,
  UPDATE_RECENT_SEARCHED,
  UPDATE_SHOW_MASTER_SEARCH_DATA_LIST,
  UPDATE_LATEST_NEW_REPORT,
  UPDATE_USER_HRID,
  GET_UPLOAD_PERCENTAGE,
  UPDATE_REPORTS_COUNT,
  FETCH_REPORT,
  FETCH_REPORT_DETAIL,
  SHOW_DOWNLOAD_HISTORY
} from "redux/actions/actionTypes";

const STATE_SLICE_NAME = "selfService";

const initialState = {
  apiCallStatus: {},
  videoName: "",
  isUserAuthenticate: false,
  accessToken: null,
  showOverlay: false,
  userDetails: null,
  publishData: [],
  draftData: [],
  favReportListData: [],
  reports: [],
  preferredView: "",
  favCategoryReportData: [],
  searchedReportsData: [],
  categoryReportData: [],
  noFavData: false,
  noReportData: false,
  noContactData: false,
  masterSearchedData: [],
  recentSearchedData: [],
  showMasterSearchData: false,
  latestReport: [],
  userHrId: "",
  showPageOverlay: false,
  pageOverlayCount: 0,
  videoPercentage: 0,
  reportsCount: {},
  reportDetail: {},
  showDownloadHistory: false
};

/**
 * Increment the Count of Active API Calls for an apiIdentifier
 * @param {*} stateObj current State-Slice in redux
 * @param {*} apiCallIdentifier API Identifier
 */
function incrementApiCall(stateObj, apiCallIdentifier) {
  // initialize object-property if its not initialized.
  stateObj[apiCallIdentifier] = stateObj[apiCallIdentifier] || 0;
  stateObj[apiCallIdentifier] += 1;
  return stateObj;
}

/**
 * Decrement the Count of Active API Calls for an apiIdentifier
 * @param {*} stateObj current State-Slice in redux
 * @param {*} apiCallIdentifier API Identifier
 */
function decrementApiCall(stateObj, apiCallIdentifier) {
  if (stateObj[apiCallIdentifier] > 0) stateObj[apiCallIdentifier] -= 1;
  return stateObj;
}

export function selfServiceReducer(state = initialState, action) {
  switch (action.type) {
    case GET_VIDEO_NAME:
      return Object.assign({}, state, {
        videoName: action.data
      });

    case CHECK_USER_AUTHENTICATION:
      return Object.assign({}, state, {
        isUserAuthenticate: action.data
      });

    case SET_USER_TOKENS:
      return Object.assign({}, state, {
        accessToken: action.data.accessToken
      });
    case UPDATE_USER_DETAILS:
      return Object.assign({}, state, {
        userDetails: action.data
      });
    case GET_DRAFT_REPORT:
      return Object.assign({}, state, {
        darftData: action.data
      });

    // case GET_PUBLISH_REPORT:
    //   return Object.assign({},state,{
    //     publishData :action.data
    //   })

    case UPDATE_REPORT_FAV_LISTING:
      return Object.assign({}, state, {
        favReportListData: action.data
      });

    case UPDATE_PREFERRED_VIEW:
      return Object.assign({}, state, {
        preferredView: action.data
      });

    case UPDATE_REPORT_FAV_LISTING_BY_CATEGORY:
      return Object.assign({}, state, {
        favCategoryReportData: action.data
      });

    case UPDATE_SEARCHED_REPORTS_DATA:
      return Object.assign({}, state, {
        searchedReportsData: action.data
      });

    case UPDATE_MASTER_SEARCHED_DATA:
      return Object.assign({}, state, {
        masterSearchedData: action.data
      });

    case UPDATE_REPORT_LISTING_DATA_BY_CATEGORY:
      return Object.assign({}, state, {
        categoryReportData: action.data
      });

    case UPDATE_NO_FAV_DATA:
      return Object.assign({}, state, {
        noFavData: action.data
      });

    case UPDATE_NO_REPORT_DATA:
      return Object.assign({}, state, {
        noReportData: action.data
      });

    case UPDATE_NO_CONTACT_DATA:
      return Object.assign({}, state, {
        noContactData: action.data
      });

    case UPDATE_RECENT_SEARCHED:
      return Object.assign({}, state, {
        recentSearchedData: action.data
      });
    case UPDATE_OVERLAY_STATE:
      return Object.assign({}, state, {
        showOverlay: action.data
      });

    case UPDATE_SHOW_MASTER_SEARCH_DATA_LIST:
      return Object.assign({}, state, {
        showMasterSearchData: action.data
      });

    case UPDATE_LATEST_NEW_REPORT:
      return Object.assign({}, state, {
        latestReport: action.data
      });

    case UPDATE_USER_HRID:
      return Object.assign({}, state, {
        userHrId: action.data
      });

    case GET_UPLOAD_PERCENTAGE:
      return Object.assign({}, state, {
        videoPercentage: action.data
      });
    case UPDATE_REPORTS_COUNT:
      return Object.assign({}, state, {
        reportsCount: action.data
      });
    case UPDATE_REPORT_LISTING_DATA:
    case FETCH_REPORT:
      return Object.assign({}, state, {
        reports: action.data
      });
    case BEGIN_API_CALL:
      return Object.assign({}, state, {
        apiCallStatus: incrementApiCall(
          state.apiCallStatus,
          action.apiIdentifier
        )
      });
    case END_API_CALL:
      return Object.assign({}, state, {
        apiCallStatus: decrementApiCall(
          state.apiCallStatus,
          action.apiIdentifier
        )
      });
    case FETCH_REPORT_DETAIL:
      return Object.assign({}, state, {
        reportDetail: action.data
      });
    case SHOW_DOWNLOAD_HISTORY:
      return Object.assign({}, state, {
        showDownloadHistory: action.data
      });
    default:
      return state;
  }
}

export const selectors = {
  getUserRoles: appState => {
    let returnValue = {};
    if (appState[STATE_SLICE_NAME]) {
      returnValue = appState[STATE_SLICE_NAME]?.userDetails?.userRoles || [];
    }
    return returnValue;
  },

  getReportDetail: appState => {
    let returnValue = {};
    if (appState[STATE_SLICE_NAME]) {
      returnValue = appState[STATE_SLICE_NAME]?.reportDetail || {};
    }
    return returnValue;
  }
};
